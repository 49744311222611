import { createApp } from 'vue';
import ShoppingCart from './src/components/ShoppingCart.vue';

// Log errors users receive.
import * as Sentry from '@sentry/vue';

if (typeof vueComponents !== 'undefined') {
    // Make sure media queries are available in Vue components.
    Foundation.MediaQuery._init();

    // Loop through each registered card slider and initialize them.
    vueComponents.forEach(({ type, container, props }) => {
        let app;
        if (type === 'shopping-cart') {
            app = createApp(ShoppingCart, props);
        }

        if (typeof app !== 'undefined') {
            app.provide('vueShoppingCart', window.vueShoppingCart);

            Sentry.init({
                app,
                dsn: 'https://7869f37088784830b0cd5b5733e21b2d@sentry.io/1202328',
                integrations: [new Sentry.BrowserTracing()],
                tracesSampleRate: 0.2,
            });

            app.mount(container);
        }
    });
}
