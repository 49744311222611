<template>
	<div class="shopping-cart-button">
		<a :href="vueShoppingCart.signUpUrl" :title="vueShoppingCart.cartTitle">
			<iconify-icon class="iconify icon-lg icon-fw" icon="ic:outline-login"></iconify-icon>
			<span class="count">{{ cart != null && cart.length == 0 ? 0 : cart.length }}</span>
		</a>
	</div>
</template>

<script setup>
import { inject } from 'vue';
import { useLocalStorage } from '@vueuse/core';

const vueShoppingCart = inject('vueShoppingCart');
const cart = useLocalStorage('cart', []);
</script>
